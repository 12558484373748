@use '../Global/' as *;

.c-heading {
 @include font-default;
}

.c-headingtitle {
 font-weight: normal;

 & > * + * {
  margin-top: var(--s1);
 }
}

.c-headingtitle__text {
 font-size: rem(24);
}

.c-headingtitle__text--primary {
 span {
  display: block;
 }

 span:first-child {
  font-weight: 900;
  font-size: rem(80);
  line-height: 1.2;
 }
 span:last-child {
  font-weight: 400;
  font-size: rem(32);
 }
}

.c-headingtitle__text--secondary {
 font-weight: 400;
}
