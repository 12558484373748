@use '../Global/' as *;

.c-incContact {
	@include font-default;
	font-family: 'Noto Sans JP', sans-serif;
	background-color: #004098;
	position: relative;
	overflow: hidden;
}

.c-incContact__wrapper {
	color: #fff;
	padding: 60px 20px 138px;
	text-align: center;
	position: relative;
	z-index: 1;
}

.c-incContact__content {
	margin-top: 35px;
}
.c-incContact__textHead {
	font-size: 18px;
	font-weight: 700;
	letter-spacing: 0;
}
.c-incContact__textTel {
	font-size: 48px;
	color: #fae427;
	font-weight: 700;
	line-height: 140%;
	letter-spacing: 0;
}
@media screen and (max-width: 767px) {
	.c-incContact__content {
		margin-top: 48px;
	}
	.c-incContact__textTel {
		font-size: 32px;
	}
	.c-incContact__textAbout {
		display: none;
	}
}
@media screen and (min-width: 768px) {
	.c-incContact__textTel a {
		text-decoration: underline #fae42700;
		transition: .2s;
	}
	.c-incContact__textTel a:hover {
		text-decoration: underline;
	}
}
.c-incContact__textAbout {
	font-size: 16px;
	margin-top: 6px;
	letter-spacing: 0;
}
.c-incContact__cta {
	margin-top: 40px;
}
.c-incContact__cta a {
	transition: .2s;
	background-color: #fff;
	color: #004098;
	display: inline-block;
	padding: 54px 78px;
	border-radius: 9999px;
}
@media screen and (max-width: 767px) {
	.c-incContact__cta {
		margin-top: 64px;
	}
	.c-incContact__cta a {
		padding: 24px;
		width: 100%;
	}
}
@media screen and (min-width: 768px) {
	.c-incContact__cta a:hover {
		background-color: #fae427;
	}
}
.c-incContact__ctaText {
	display: inline-block;
	font-weight: 900;
	font-size: 24px;
	letter-spacing: 0;
}
.c-incContact__bg {
	position: absolute;
	opacity: .2;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
}
.c-incContact__bg img {
	display: block;
}
@media screen and (max-width: 767px) {
	.c-incContact__ctaText {
		font-size: 16px;
	}
	.c-incContact__wrapper {
		padding: 80px 48px 130px;
	}
}
