@use '../Global/' as *;


/* 
ーーーーーーーーーーーーーーーーーーーーーー
会社概要CSS START
ーーーーーーーーーーーーーーーーーーーーーー
*/
body{
    background-color: #fff;
}
main{
    overflow: hidden;
}
.p-company__l-inner{
    margin: 0 auto;
    max-width: 1095px;
    width: 100%;
    padding: 0 15px;
}
.p-company__flex {
    display: flex;
}
.p-company__flex_pc {
    display: flex;
}
.p-company__flex_sp {
    display: block;
}

@media screen and (max-width: 767px) {
    .p-company__flex_pc {
        display: block;
    }
    .p-company__flex_sp {
        display: flex;
    }
}

.p-company__spOnly{
    display: block;
}
.p-company__pcOnly{
    display: none;
}

@media screen and (max-width: 767px){
    .p-company__pcOnly{
        display: block;
    }
    .p-company__spOnly{
        display: none;
    }
}

.p-company__greetingBigTitle{
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 100%;
    letter-spacing: 4px;
    margin-top: 160px;
    margin-bottom: 80px;
    text-align: center;
    color: #004098;
}

@media screen and (max-width: 767px) {
    .p-company__greetingBigTitle {
        font-size: 24px;
        letter-spacing: 2.4px;
    }
    .banner__Wrapper {
        margin-top: 80px;
    }
}

.p-campany__greetingTitleArea {
    padding-top: 59px;
    background-color: #ECF9FF;
    text-align: center;
    padding-top: 72px;
    padding-bottom: 72px;
}
.p-campany__greetingTitle{
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: 100%;
    letter-spacing: 1.4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.p-campany__greetingTitle::before,
.p-campany__greetingTitle::after {
    content: "";
    width: 40px;
    height: 1px;
    background-color: #1B1A2A;
}
.p-campany__greetingTitle::before {
    margin-right: 20px;
}
.p-campany__greetingTitle::after {
    margin-left: 20px;
}
.p-campany__greetingTitle_sm{
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: 100%; 
    letter-spacing: 2.4px;
    margin-top: 32px;
}
.p-campany__greetingName{
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: 100%; 
    letter-spacing: 2px;
    margin-top: 40px;
}
.p-campany__greetingPosition{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 1.4px;
    display: block;
    margin-bottom: 5px;
}
.p-campany__greetingImageContent {
    justify-content: center;
    column-gap: 107px;
    margin-top: 30px;
}
.p-campany__greetingImageContent img{
    width: 464px;
    height: auto;
    margin-top: -87px;
    margin-top: calc(-87px + -30px);
    border-radius: 40px;
}
.p-campany__greetingImageText:nth-of-type(2){
    margin-top: 25px;
    margin-bottom: 25px;
}
.p-campany__greetingImageText{
    max-width: 36.6vw;
    width: 100%;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.8px;
}

@media screen and (max-width: 767px) {
    .p-campany__greetingTitleArea {
        padding-top: 59px;
        padding-bottom: 243px;
    }
    .p-campany__greetingImageContent {
        text-align: center;
    }
    .p-campany__greetingImageContent img{
        margin-top: calc(-203px + -30px);
    }
    .p-campany__greetingImageText{
        max-width: initial;
        margin-top: 32px;
        text-align: left;
    }
}


/* 
ーーーーーーーーーーーーーーーーーーーーーー
swiper
ーーーーーーーーーーーーーーーーーーーーーー
*/
.swiper {
    width: 70%;
    width: 100%;
    margin-top: 66px;
}
.swiper img {
    width: 100%;
}
/* プログレスバーの高さ変更 */
.swiper-horizontal>.swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal {
    height: 4px;
}
/* プログレスバーの背景色変更 */
.swiper-pagination-progressbar {
    background-color: #D9D9D9;
}
/* プログレスバーの色変更 */
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background-color: #004098;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}
.swiper-pagination {
    position: relative;
}
/* 画像サイズ調整 */
.swiper-slide img {
    height: auto;
    width: 100%;
    /* width: 294px; */
    border-radius: 20px;
}
.swiper-backface-hidden .swiper-slide .swiper-box{
    margin-right: 0 !important;
    width: 294px !important;
    height: auto !important;
}
.p-company__swiperButton_Area {
    position: absolute;
    right: 0;
    column-gap: 35px;
    align-items: start;
}
.swiper-horizontal>.swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal {
    width: 160px;
    border-radius: 2px;
}
.swiper-backface-hidden .swiper-slide{
    width: 100% !important;
    
}

@media screen and (max-width: 767px){
    .swiper{
        margin-top: 27px;
    }
    .swiper-box img{
        width: 345px;
        height: auto;
    }
    .swiper-backface-hidden .swiper-slide .swiper-box{
        width: 345px !important;
    }
    .p-company__swiperButton_Area{
        width: 86%;
        bottom: 10px;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        column-gap: 60px;
    }
}

/* ボタン */
.hovicon {
    display: inline-block;
    font-size: 45px;
    line-height: 90px;
    cursor: pointer;
    /* margin: 20px; */
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    position: relative;
    text-decoration: none;
    z-index: 1;
    color: #fff;
}
.hovicon:before {
    speak: none;
    font-size: 48px;
    line-height: 90px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    display: block;
    -webkit-font-smoothing: antialiased;
}
/* Effect 9 */
.hovicon.effect-9 {
    -webkit-transition: box-shadow 0.2s;
    -moz-transition: box-shadow 0.2s;
    transition: box-shadow 0.5s;
}
/* 背景色 */
.hovicon.effect-9.sub-a{
    background-color: rgba(0, 64, 152, 1);
}
.hovicon.effect-9.sub-a:hover {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 1);
    box-shadow: 0 0 0 10px rgb(212, 47, 47);
    box-shadow: 0 0 0 7px rgba(0, 64, 152, 1);
    color: #fff;
}

/* 
ーーーーーーーーーーーーーーーーーーーーーー
COMPANY DATA
ーーーーーーーーーーーーーーーーーーーーーー
*/
.p-company__dataContent_outer{
    margin: 0 15px;
}
.p-company__dataTable {
    margin: 0 auto;
    margin-top: 80px;
    letter-spacing: -0.02em;
}
.p-company__dataTable tr {
    border-bottom: solid 1px rgba(217, 217, 217, 1);
}

@media screen and (max-width: 767px){
    .p-company__dataTable{
        margin-top: 48px;
    }

}

/* 
ーーーーーーーーーーーーーーーーーーーーーー
HISTORY
ーーーーーーーーーーーーーーーーーーーーーー
*/
.p-company__historySect{
    position: relative;
}
.p-company__historyWrapper{
    margin-top: 152px;
    margin-bottom: 120px;
}
.p-company__historyTitle{
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: 100%; 
    letter-spacing: 3.2px;
}
.p-company__swiperArrow_content{
    column-gap: 52px;
    margin-right: 10.7vw;
}
@media screen and (max-width: 767px){
    .p-company__historyWrapper{
        margin-top: 120px;
        margin-bottom: 147px;
    }
    .p-company__swiperArrow_content{
        margin-right: initial;
        column-gap: 45px;
        left: 50%;
        transform: translateX(-20%);
    }
}

/* 
ーーーーーーーーーーーーーーーーーーーーーー
DATA
ーーーーーーーーーーーーーーーーーーーーーー
*/
.p-company__dataTitle{
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: 100%; 
    letter-spacing: 3.2px;
}
.p-company__dataWrapper{
    background-color: #ECF9FF;
    padding-top: 100px;
    padding-bottom: 160px;
}
.p-company__dataContent{
    background-color: rgba(255, 255, 255, 1);
    padding: 80px 97px 120px;
    border-radius: 50px;
}
.p-company__dataTable_left,
.p-company__dataTable_right{
    padding-top: 29px;
    padding-bottom: 28px;
}
.p-company__dataTable_left{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    background-color: rgba(248, 248, 248, 1);
    background-color: #F8F8F8;
    width: 16vw;
}
.p-company__dataTable_right{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 5.2vw;
}
.p-company__dataAccess_image img{
    width: 320px;
    height: auto;
}
.p-company__dataMap{
    width: 100%;
    margin-top: 64px;
}
.p-company__dataAccess_content{
    margin-top: 80px;
    column-gap: 64px;
    color: #333;
}
.p-company__dataAccess_test_lg{
    
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.p-company__dataAccess_text_area{
    margin-top: 9px;
}
.p-company__dataAccess_text_sm{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
}
@media screen and (max-width: 767px){
    .p-company__dataWrapper{
        padding-top: 58px;
        padding-bottom: 80px;
    }
    .p-company__dataContent{
        padding: 22px 15px 80px;
        border-radius: 20px;
    }
    .p-company__dataTitle{
        font-size: 24px;
        letter-spacing: 2.4px;
    }
    .p-company__dataTable_right{
        font-size: 15px;
        padding: 18px 13px 19px 16px;
    }
    .p-company__dataTable_left{
        padding: 18px 0 19px 10px;
        width: 26vw;
        font-size: 16px;
        text-align: left;
    }
    .p-company__dataAccess_text_area{
        margin-top: 24px;
    }
    .p-company__dataAccess_text_sm:nth-of-type(2){
        margin-top: 9px;
    }
    .p-company__dataMap{
        margin-top: 40px;
        height: 180px;
    }
}
