@use '../Global/' as *;

/* about
  ------------------------------------------------*/
section{
  overflow: hidden;
}
img{
  max-width: 100%;
  height: auto;
}

.p-about--container{
  margin: 120px 0 265px;
  @include MQ('md'){
margin-bottom: 205px;
}
}

  .p-about--titelBox{
    padding: 190px 0 110px;
    background-color: var(--skyblue);
    text-align: center;
    @include MQ('md'){
    display: flex;
    justify-content: center;
    align-items: center;
    height: 204px;
    }
    .p-about--titelBox-heading{
      font-size: 48px;
      @include MQ('md'){
        padding: 0;
        font-size: 24px;
          }
          .p-about--titelBox-headingImg{
            @include MQ('md'){
              width: 50px;
              height: 51px;
                }
          }
    }
    .p-about--titelBox-subheading{
      display: inline-block;
      margin-bottom: 24px;
      font-size: 24px;
      @include MQ('md'){
        margin: 0;
        font-size: 14px;
          }
    }
      }
      .p-about--card{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include MQ('md'){
          flex-direction: column;
          justify-content: center;
    }
        &:nth-child(2){
          margin-top: 120px;
        margin-bottom: 120px;
        }
        &:nth-child(1):before{
          content: "";
          position: absolute;
          left: -213px;
          bottom: 3px;
          width: 384px;
          height: 384px;
          background-color: var(--skyblue);
          border-radius: 50%;
          background-clip: content-box;
          z-index: -2;
          @include MQ('md'){
            left: -124px;
            top: -60px;
            bottom: 0;
            width: 273px;
            height: 273px;
      }
        }
        &:nth-child(2):before{
          content: "";
          position: absolute;
          left: 30%;
          bottom: 12px;
          width: 384px;
          height: 384px;
          background-color: var(--skyblue);
          border-radius: 50%;
          background-clip: content-box;
          z-index: -2;
          @include MQ('md'){
            left: -124px;
            top: -60px;
            bottom: 0;
            width: 273px;
            height: 273px;
      }
        }
        &:nth-child(3):before{
          content: "";
          position: absolute;
          left: -213px;
          bottom: 13px;
          width: 384px;
          height: 384px;
          background-color: var(--skyblue);
          border-radius: 50%;
          background-clip: content-box;
          z-index: -2;
          @include MQ('md'){
            left: -124px;
            top: -60px;
            bottom: 0;
            width: 273px;
            height: 273px;
      }
        }
        .p-about--card-img{
          display: block;
        }
        .p-about--cardLeft{
          margin-right: 80px;
          flex: 0 0 48.182%;
          margin-top: 78px;
          @include MQ('md'){
            margin-top: 0;
            margin-right: 0;
            margin-bottom: 20px;
            flex: auto;
      }
        }
.p-about--card-title{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 32px;
  color: var(--blue);
  margin-bottom: 40px;
  padding-bottom: 9px;
    border-bottom: 1px solid var(--blue);
    @include MQ('md'){
      font-size: 20px;
      margin-bottom: 25px;
      padding-bottom: 2px;
    }
    img{
      margin-right: 32px;
      @include MQ('md'){
        width: 40px;
        height: 35px;
        margin-right: 10px;
  }
    }
}
.p-about--card-textarea{
font-size: 16px;
letter-spacing: 0px;
}
      }
      .p-about--cardRight{
        flex-direction: row-reverse;
        .p-about--cardRight{
          margin-left: 80px;
          flex: 0 0 48.182%;
          margin-top: 78px;
          @include MQ('md'){
            margin-top: 0;
            margin-left: 0;
            margin-bottom: 20px;
            flex: auto;
      }
        }
      }
    .p-about--kindness{
      position: relative;
      padding-bottom: 120px;
      &::before{
        content: "";
        position: absolute;
        top: -96px;
        right: calc(50% - 962px);
        width: 1924px;
        height: 1539px;
        background-color: var(--skyblue);
        border-radius: 50%;
        background-clip: content-box;
        z-index: -2;
        @include MQ('md'){
          top: -69px;
          left: calc(50% - 600px);
          width: 1220px;
          height: 1050px;
    }
      }
      .p-about--kindness-title{
        position: relative;
        font-size: 32px;
        color: var(--blue);
        text-align: center;
        margin-bottom: 83px;
        @include MQ('md'){
          font-size: 20px;
          margin-bottom: 43px;
    }
        &::before{
          content: "";
          background-image: url('../images/about/point_bg_plus1.png');
          display: block;
          position: absolute;
          top: -114px;
          left: calc(50% - 276px);
          width: 144px;
          height: 146px;
          @include MQ('md'){
            background-size: contain;
            top: -79px;
            left: calc(50% - 152px);
            width: 73px;
            height: 74px;
      }
        }
      }
      .p-about--kindness-wrap{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        @include MQ('md'){
          flex-direction: column-reverse;
    }
      }
      .p-about--kindness-textarea{
        margin-left: 80px;
        @include MQ('md'){
          margin-top: 60px;
          margin-left: 0;
    }
        .p-about--kindness-text{
          font-size: 18px;
          + .p-about--kindness-text{
            margin-top: 36px;
          }
        }
      }
    }
      .p-textRed{
    color: var(--red);
    }
