@use "../Global/" as *;

/* header
  ------------------------------------------------*/
header {
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 9999;

  .p-header--mobileBtn {
    display: none;
  }
  @include MQ("xl") {
    .p-header--mobileBtn {
      display: block;
      position: fixed;
      z-index: 9999; /*ボタンを最前面に*/
      top: 15px;
      right: 15px;
      cursor: pointer;
      width: 50px;
      height: 50px;
      background-color: var(--red);
      border-radius: 50%;
      span {
        display: inline-block;
        transition: all 0.4s;
        position: absolute;
        left: 14px;
        height: 1px;
        border-radius: 5px;
        background: var(--white);
        width: 45%;
        &:nth-child(1) {
          top: 23px;
        }
        &:nth-of-type(1)::before {
          content: "menu";
          position: absolute;
          top: -15px;
          left: -2px;
          color: var(--white);
          font-size: 1px;
        }
        &:nth-child(2) {
          top: 29px;
          width: 32%;
          left: 21px;
        }
        &:nth-child(3) {
          top: 35px;
        }
      }
      &.js-active {
        span {
          &:nth-child(1) {
            top: 21px;
            left: 16px;
            transform: translateY(6px) rotate(-45deg);
            width: 36%;
          }
          &:nth-of-type(1)::before {
            content: "close";
            transform: translateY(0) rotate(45deg);
            top: -18px;
            left: 8px;
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            top: 33px;
            left: 16px;
            transform: translateY(-6px) rotate(45deg);
            width: 36%;
          }
        }
      }
    }
  }
  #p-header--mobileMenu {
    @include MQ("xl") {
      position: fixed;
      z-index: 999;
      top: -120%;
      left: 0;
      width: 100%;
      height: 100vh; /*ナビの高さ*/
      background: var(--red);
      transition: all 0.6s;
      &.js-panelactive {
        top: 0;
      }
      .p-header--mobileMenu-item {
        display: block;
        position: absolute;
        z-index: 999;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .p-header--globalMenu-list {
          color: var(--white);
          margin-left: 0px;
          margin-right: 0px;
          margin-bottom: 56px;
        }
      }
    }
  }
}

.variable-hidden {
  display: none;
  @include MQ("xl") {
    display: block;
  }
}

.p-header--wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.1);
  .p-header--logo {
    margin-left: 60px;
    picture {
      vertical-align: middle;
      img {
        vertical-align: middle;
        width: 100%;
        min-width: 220px;
      }
    }
  }

  @include MQ('md'){
    .p-header--logo {
      margin-left: 15px;
    }
  
  }
  .p-header--globalMenu-item {
    display: flex;
    .p-header--globalMenu-list {
      font-size: 16px;
      white-space: nowrap;
      text-align: center;
      margin-right: 24px;
      &:first-child {
        margin-left: 24px;
      }
      &:last-child {
        margin-right: 40px;
      }
      a {
        &:hover {
          color: var(--red);
        }
      }
    }
  }
}

.p-header--contact {
  display: flex;
  a {
    display: flex;
    align-items: center;
    gap: 7px;
    padding: 10px 40px;
    height: 80px;
    background-color: var(--red);
    color: var(--white);
    white-space: nowrap;
    &:hover {
      background-color: unset;
      color: var(--red);
      border: 3px solid var(--red);
    }
  }
  @include MQ("xl") {
    display: none;
  }
  span {
    display: inline-block;
    vertical-align: middle;
    .p-header--mail-icon {
      &:hover {
        filter: invert(26%) sepia(86%) saturate(6869%) hue-rotate(347deg) brightness(83%) contrast(120%);
      }
    }
  }
}


