  /* footer
  ------------------------------------------------*/
footer{
  background-image: url('../images/common/bg_halfcirclePC.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -20px top;
}
  .p-footer--wrapper{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: 500px;
    padding-bottom: 9px;
    .p-footer--info{
      .p-footer--textareaLeft{
        .p-footer--officeName{
          font-size: 16px;
          margin-bottom: 6px;
        }
        .p-footer--postCode{
          padding-bottom: 2px;
        }
        .p-footer--postCode,.p-footer--telLink{
        font-size: 14px;

        }
      }
    }

    .p-footer--nav nav {
      &:first-child {
        margin-bottom: 13px;
      }
    }
    .p-footer--nav-item {
    display: flex;
    justify-content: flex-end;
    .p-footer--nav-list{
      margin-left: 24px;

      &:hover{
        color: var(--red);
      }
    }
      .p-footer--nav-list:nth-child(3){
        margin-right: -30px;
      }
      .p-footer--nav-list a{
        font-size: 14px;
      }
    }
    .p-footer--copyright{
      font-size: 12px;
      text-align: end;
    }
    }
    .js-pageTop {
      cursor: pointer;
      position: fixed;
      right: 12%;;
      bottom: 158px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 81px;
      height: 81px;
      border-radius: 50%;
      transition: .3s;
      color: var(--white);
      background: var(--red);
      z-index: 9999;
      
    /*   デフォルトは非表示 */
      // opacity: 0;
      a{
        position: relative;
        margin-top: 10px;
      &::before{
        position: absolute;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: '\f062';
        width: 32px;
        height: 32px;
        top: -18px;
      text-align: center;
      }
    }
    }

    @media screen and (max-width: 1199px) {

      .p-footer--wrapper {
        display: block;

        .p-footer--copyright{
          text-align: start;
        }
        .p-footer--nav-item {
          justify-content: flex-start;
        }
        
      }

      .p-footer--nav{
        border-top: solid 1px var(--black);
        margin-top: 32px;
        padding-top: 32px;
      }

      
    }

    @media screen and (max-width: 767px) {
      footer{
        background-position: -220px top;
      }
    }








