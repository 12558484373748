@use '../Global/' as *;

.l-grid {
 display: grid;

 grid-gap: var(--s3);
 --minimum: 330px;
}

@supports (width: #{'min(var(--minimum), 100%)'}) {
 .l-grid {
  grid-template-columns: repeat(auto-fit, minmax(#{'min(var(--minimum), 100%)'}, 1fr));
 }
}
