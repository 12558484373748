:root {
  --white: #fff;
  --gray: #939393;
  --lightgray: #f2f2f2;
  --yellow: #fae427;
  --black: #1b1a2a;
  --skyblue: #ecf9ff;
  --blue: #004098;
  --red: #E60012;
}

