@use '../Global/' as *;

html,
body {
 margin: 0;
 padding: 0;
 width: 100%;
}

* {
 box-sizing: border-box;

 &::before,
 &::after {
  box-sizing: border-box;
 }
}

h2 {
 font-size: 10rem;
}

html {
 font-size: $basefontsize + px;
 font-family: $font-family;
}

body {
 background-color: var(--white);
 color: var(--black);
}

main {
 overflow-x: hidden;

 flex: 1;
}

ul {
 margin: 0;
 padding: 0;
 list-style: none;
}

p {
 margin: 0;

 @include font-default;
}

a {
 color: inherit;
 text-decoration: none;
}

*[lang='en'] {
 font-weight: 400;
 font-style: normal;
 font-family: $font-familyEN;
}
