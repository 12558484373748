@use '../Global/' as *;

.p-serviceHead {
	padding: 190px 0 110px;
	background-color: #ecf9ff;
}
.p-serviceHead__title {
	color: #004098;
	text-align: center;
	font-weight: 900;
	font-size: 40px;
}
.p-service__title {
	display: flex;
	text-align: center;
	font-size: 32px;

	align-items: center;
	gap: 16px;
	justify-content: center;
}
.p-service__title::before,
.p-service__title::after {
	display: block;
	width: 1em;
	height: 1em;
	background-image: url(../images/service/icon_whieel.png);
	background-size: contain;
	content: '';
	margin-bottom: -4px;
}
@media screen and (max-width: 767px) {

	.p-serviceHead__title {
		font-size: 24px;
	}
	.p-service__title {
		font-size: 20px;

		gap: 10px;
	}
}

.p-service__about {
	display: flex;
	margin-top: 60px;

	gap: 40px;
	align-items: center;
}
.p-service__aboutImage img {
	max-width: 100%;
}
.p-service__aboutText p {
	letter-spacing: .1em;
}
.p-service__aboutText > * + * {
	margin-top: 1em;
}


@media screen and (max-width: 767px) {
	.p-service__about {
		flex-direction: column;

		gap: 16px;
	}

	.p-service__aboutText ul {
		text-align: center;
	
		li {
			margin-bottom: 0.4em;
		}
	}
}

.p-service01 {
	padding-top: 90px;
	padding-bottom: 120px;
}
.p-service01__contents {
	display: grid;
	margin-top: 60px;

	grid-template-columns: repeat(3,1fr);
	gap: 48px 40px;
}
@media screen and (max-width: 991px) {
	.p-service01__contents {
		grid-template-columns: repeat(2,1fr);
	}
}

.p-service01__image {
	max-width: 100%;
}
.p-service01__text {
	margin-top: 16px;
	color: #004098;
	font-weight: 700;
	font-size: 20px;
}

@media screen and (max-width: 767px) {
	.p-service01 {
		padding-top: 40px;
		padding-bottom: 80px;
	}
	.p-service01__contents {
		grid-template-columns: 1fr;
		gap: 40px;
	}

	.p-service01__text {
		font-size: 18px;

	}
}

.p-service02 {
	padding-top: 100px;
	padding-bottom: 80px;
	background-color: #ecf9ff;
}
.p-service02__flows {
	margin-top: 80px;
}
.p-service02__flow {
	display: flex;
	margin-top: 64px;

	gap: 40px;
	align-items: center;
}
.p-service02__flowImage {
	width: 18.18%;
}
.p-service02__flowImage img {
	max-width: 100%;
}
.p-service02__flowHead {
	color: #e60012;
	font-weight: 900;
	font-size: 18px;
	line-height: 1;
}
.p-service02__flowTitle {
	margin-top: 20px;
	letter-spacing: .1em;
	font-weight: 700;
	font-size: 28px;
	line-height: 1.3;
}
.p-service02__flowText {
	margin-top: 12px;
	letter-spacing: .1em;
	font-size: 20px;
	line-height: 1;
}
.p-service02__price {
	margin-top: 80px;
}
.p-service02__priceTable {
	margin: 0 auto;
	padding: 64px 102px;
	max-width: 610px;
	border-radius: 10px;
	background-color: #fff;
}
.p-service02__priceTitle {
	text-align: center;
	font-weight: 900;
	font-size: 20px;
}
.p-service02__priceItems {
	display: flex;
	flex-direction: column;
	margin-top: 48px;

	gap: 10px;
}
.p-service02__priceItem {
	display: flex;
	padding-bottom: 20px;
	border-bottom: dotted 4px #d5d5d5;
	letter-spacing: .1em;

	gap: 20px;
	justify-content: space-between;
	align-items: baseline;
}
.p-service02__priceDescription {
	letter-spacing: .1em;
	font-size: 18px;
}
.p-service02__priceAmount {
	letter-spacing: 0;
	font-size: 14px;
}
.p-service02__priceAmount span {
	letter-spacing: .1em;
	font-weight: 700;
	font-size: 24px;
}
.p-service02__priceNotes {
	margin: 40px auto 0;
	max-width: 610px;
}
.p-service02__priceNote {
	letter-spacing: .1em;
	line-height: 1.5;
}
@media screen and (max-width: 767px) {
	.p-service02__flow {
		flex-direction: column;
		margin-top: 80px;
	}
	.p-service02__flowImage {
		width: 58%;
	}
	.p-service02__flowTitle {
		margin-top: 16px;
		font-size: 18px;
	}
	.p-service02__flowText {
		margin-top: 10px;
		font-size: 16px;
	}
	.p-service02__priceTable {
		padding: 40px 15px;
	}
	.p-service02__priceTitle {
		font-size: 16px;
	}
	.p-service02__priceDescription {
		font-size: 16px;
	}
	.p-service02__priceAmount {
		font-size: 12px;
	}
}

.p-service03__contents {
	display: flex;
	margin-top: 66px;

	gap: 40px;
}
.p-service03 {
	padding-top: 120px;
	padding-bottom: 120px;
}
.p-service03__content {
	flex: 1;
}
.p-service03__contentHead {
	color: #004098;
	text-align: center;
	font-weight: 900;
	font-size: 24px;
}
.p-service03__contentImage {
	margin-top: 24px;
	max-width: 100%;
}
.p-service03__contentText {
	margin-top: 24px;
	letter-spacing: .1em;
}
@media screen and (max-width: 767px) {
	.p-service03 {
		padding-top: 100px;
		padding-bottom: 86px;
	}
	.p-service03__contents {
		flex-direction: column;
		margin-top: 48px;
	}
	.p-service03__contentHead {
		font-size: 18px;
	}
	.p-service03__contentImage {
		margin-top: 16px;
	}
	.p-service03__contentText {
		margin-top: 16px;
		font-size: 16px;
	}
}

.p-service04 {
	padding-top: 100px;
	padding-bottom: 160px;
	background-color: #ecf9ff;
}
.p-service04__prices {
	display: flex;
	margin-top: 64px;

	gap: 24px;
}
.p-service04__price {
	padding: 56px 32px;
	background-color: #fff;
	box-shadow: 11px 11px 20px 3px #3961781a;
}
.p-service04__priceHead {
	text-align: center;
	letter-spacing: .1em;
	font-size: 20px;
}
.p-service04__priceImg {
	margin-top: 40px;
	max-width: 100%;
}
.p-service04__notes {
	margin-top: 40px;
}
@media screen and (max-width: 767px) {
	.p-service04 {
		padding-top: 56px;
		padding-bottom: 100px;
	}
	.p-service04__prices {
		flex-direction: column;
		margin-top: 40px;

		gap: 40px;
	}
}
