@use "../Global/" as *;

.p-top__mv {
    position: relative;
    overflow: hidden;
    padding-top: 5rem;

    &--imgArea {
        // height: 100vh;
        max-width: 100%;
        padding-bottom: 180px;

        img {
            height: 100%;
            width: 100%;
        }
    }

    &--bg {
        width: 840px;
        height: 840px;
        line-height: 840px;
        background-color: var(--skyblue);
        border-radius: 50%;
        text-align: center;
        position: absolute;
        left: -160px;
        bottom: 0;
        z-index: -10;
    }
}

@media screen and (max-width: 767px) {
    .p-top__mv {
    &--bg {
        width: 400px;
        height: 400px;
        line-height: 400px;
        left: -100px;
        bottom: 0;
    }


    &--imgArea {
        padding-bottom: 80px;
    }
}
}

.p-top__banner {
    margin-top: -80px;

    &--imgArea {
        img {
            width: 100%;
        }
    }
}

@media screen and (max-width: 767px) {
    .p-top__banner {
        margin-top: -60px;
    }
}

.p-top__service {
    margin-top: 80px;
    padding-bottom: 120px;

    &--titleArea {
        text-align: center;
        margin: 0 auto 60px;
        max-width: 180px;

        img {
            width: 100%;
        }
    }

    &--subTitle {
        font-size: 20px;
        font-weight: bold;
    }

    &--list {
        display: grid;
        max-width: 1100px;
        grid-template-columns: 1fr 1fr;
        gap: 40px;
    }

    &--item {
        max-width: 520px;
        padding: 40px 50px;
        border-radius: 20px;
        background: var(--white);
        box-shadow: 11px 11px 20px 3px rgba(57, 97, 120, 0.1);
        text-align: center;
    }

    &--text {
        font-weight: bold;
        color: var(--blue);
    }

    &--image {
        padding: 40px 0 24px 0;
    }
}

@media screen and (max-width: 767px) {
    .p-top__service {
        margin-top: 60px;
        margin-bottom: 100px;

        &--list {
            display: block;
        }

        &--item {
            margin-bottom: 60px;
            padding: 40px 24px;
            max-width: 100%;
        }

        &--text {
            font-size: 14px;
        }
    }
}

.triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 40px 0 40px;
    border-color: var(--yellow) transparent transparent transparent;
    display: block;
    margin: 16px auto 0;
}

.p-top__link {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color: var(--blue);
    transition: all 0.4s;

    &--text {
        font-size: 24px;
        font-weight: bold;
    }

    &--icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 42px;
        height: 42px;
        background-color: var(--blue);
        border-radius: 50%;
        transition: all 0.4s;
        z-index: 2;

        &::after {
            content: "";
            background-image: url(../images/top/arrow.svg);
            width: 22px;
            height: 20px;
        }

        &:hover {
            transform: scale(1.6);
        }
    }
}

@media screen and (max-width: 767px) {
    .p-top__link {
        &--text {
            font-size: 20px;
        }
    }
}

.p-top__service02 {
    padding-bottom: 120px;

    &--bg {
        background-color: var(--skyblue);
        margin-top: -200px;
        z-index: -1;
        position: sticky;
    }

    &--title {
        font-size: 32px;
        text-align: center;
        margin-top: 80px;
        margin-bottom: 64px;
        padding-top: 120px;
    }

    &--list {
        display: grid;
        max-width: 1100px;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 40px;
        padding-bottom: 160px;
    }

    &--itemImg img {
        width: 100%;
    }

    &--itemTitle {
        margin: 12px 0;
        position: relative;
        padding-left: 0.6em;
        &::before {
            width: 4px;
            height: 100%;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            background: var(--blue);
        }

        
    }

    &--container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        margin-top: -160px;
    }

    &--box {
        max-width: 540px;
        padding: 40px 80px;
        border-radius: 20px;
        background: var(--white);
        box-shadow: 11px 11px 20px 3px rgba(57, 97, 120, 0.1);
    }

    &--boxTitle {
        text-align: center;
        margin-bottom: 40px;
    }

    &--boxList {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }

    &--boxText {
        font-size: 14px;
        font-weight: bold;
    }

    &--boxItem {
        font-weight: bold;
        font-size: 20px;
        // margin-bottom: 16px;
        background: url(../images/top/check_box.svg) no-repeat 0 0;
        background-size: auto 32px;
        padding-left: 40px;
    }
}

@media screen and (max-width: 767px) {
    .p-top__service02 {

        &--bg {
            margin-top: -400px;
        }

        &--title {
            font-size: 20px;
            margin-bottom: 40px;
        }
        &--container {
            display: block;
        }

        &--list {
            display: block;
            padding-bottom: 80px;
        }

        &--item {
            margin-bottom: 60px;
        }

        &--box {
            padding: 24px 20px 40px;
            max-width: 100%;
            &:first-child {
                margin-bottom: 40px;
            }
        }

        &--boxItem {
            font-size: 16px;
            background-size: auto 20px;
            padding-left: 26px;
        }

        &--boxText {
            font-size: 12px;
        }

        
    }
}

    @media screen and (max-width: 767px) {

        .p-top__service02--boxItem-Image{
            width: 47%;

            img {
                width: 100%;
            }
        }

    }


.p-top__about {
    padding-top: 180px;
    padding-bottom: 160px;
    position: relative;
    overflow: hidden;

    &--bg {
        width: 1460px;
        height: 1460px;
        line-height: 1460px;
        background-color: var(--skyblue);
        border-radius: 50%;
        text-align: center;
        position: absolute;
        right: -20%;
        bottom: -70%;
        z-index:-1;
        }

    &--title {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    &--titleImage {
        max-width: 200px;

        img {
            width: 100%;
        }
    }

    &--titleText {
        font-size: 40px;
        font-weight: bold;
    }

    &--media {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;
    }

    &--mediaImage {
        max-width: 480px;

        img {
            width: 100%;
        }
    }

    &--mediaBody {
        margin-left: 16px;
    }

    &--mediaTitle {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 32px;
        display: flex;
        align-items: center;
        gap: 1em;

        &::after,
        &::before {
            border-top: 1px solid var(--black);
            content: "";
            width: 1em;
        }
    }

    &--mediaItem {
        font-weight: bold;
            font-size: 18px;
            margin-bottom: 16px;
        position: relative;
        padding-left: 1em;
        &::before {
            border-radius: 50%;
            width: 8px;
            height: 8px;
            display: block;
            position: absolute;
            left: 0;
            top: 0.6em;
            content: "";
            background: var(--yellow);
        }

    }

    &--mediaButton {
        margin-top: 40px;

        .p-top__link--about {
            justify-content: flex-start;
        }
    }
}



@media screen and (max-width: 767px) {
    .p-top__about {
        padding-bottom: 80px;

        &--bg {
            width: 1000px;
            height: 1000px;
            line-height: 1000px;
            left: -20%;
            bottom: -6%;
            }

        &--title {
            gap: 5px;
        }
        &--titleImage {
            width: 160px;
        }

        &--titleText {
            font-size: 20px;
        }

        &--media {
            display: block;
        }

        &--mediaImage {
            max-width: 100%;
        }
        &--mediaBody {
            margin-left: 0;
        }

        &--mediaTitle {
            font-size: 20px;
            justify-content: center;
            margin-top: 40px;
        }

        &--mediaItem {
            font-size: 15px;
        }

        &--mediaButton {
            margin-top: 40px;
    
            .p-top__link--about {
                justify-content: center;
            }
        }
    }
}

.p-top__news {
    margin-top: 120px;

    &--titleArea {
        max-width: 160px;
        text-align: center;
    }

    &--title {
        img {
            width: 100%;
        }
    }

    &--subTitle {
        font-size: 20px;
        font-weight: bold;
    }

    &--contents {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &--body {
        width: 70%;
    }

    &--list {
        display: flex;
        align-items: center;
        gap: 64px;
        border-top: solid 1px var(--blue);
        padding: 29px 16px;
    }

    &--day {
        font-size: 14px;
        color: var(--blue);
    }

    &--cat {
        color: var(--white);
        background-color: var(--blue);
        padding: 6px 18px;
        border-radius: 40px;
        font-size: 11px;
        margin-top: 8px;
    }

}

@media screen and (max-width: 767px) {
    .p-top__news {
        margin-top: 80px;
        margin-bottom: 80px;

        &--contents {
            display: block;
        }

        &--titleArea {
            margin: 0 auto 32px;
        }

        &--body {
            width: 100%;
        }

        &--list {
            display: block;
            padding: 16px 0;
        }

        &--dayArea {
            display: flex;
            align-items: center;
            gap: 32px;
            margin-bottom: 12px;
        }

        &--cat {
            margin-top: 0;
        }
    }
}
