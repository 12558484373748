

$breakpoints: (
  'sm': (min-width: 576px),
  'md': (max-width: 767px),
  'lg': (min-width: 992px),
  'xl': (max-width: 1200px),
  'xxl': (min-width: 1400px)
) !default;

@mixin MQ($breakpoint) {
 @if map-has-key($breakpoints, $breakpoint) {
  @media screen and #{inspect(map-get($breakpoints, $breakpoint))} {
   @content;
  }
 }

 // マップ型で定義されていない値が呼び出された時はエラーを返す
 @else {
  @error '指定されたブレークポイントは定義されていません。' + '指定できるブレークポイントは次のとおりです。 -> #{map-keys($breakpoints)}';
 }
}
