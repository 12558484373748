@use '../Global/' as *;

.l-frame {
 position: relative;
 padding-bottom: calc(var(--n) / var(--d) * 100%);

 --n: 9; /* 高さ */
 --d: 16; /* 幅 */

 & > * {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  overflow: hidden;

  justify-content: center;
  align-items: center;
 }

 & > img,
 > video,
 > iframe {
  width: 100%;
  height: 100%;

  object-fit: cover;
 }
}
