@use '../Global/' as *;

.l-wrapper {
    margin: 0 auto;
    padding: 0 40px;
    max-width: 1180px;
    width: 100%;
}

@media screen and (max-width: 767px) {
    .l-wrapper {
        padding: 0 30px;
    }
   }
