@use '../Global/' as *;
.p-contact_title{
    font-size: 40px;
    color: var(--blue);
    text-align: center;
    font-weight: bold;
    padding-top: 80px;
    
}
.p-contact_wrapper{
    max-width: 1095px;
    margin: 120px auto;
    border-radius: 40px;
    box-shadow: 11px 11px 20px 3px rgba(57, 97, 120, 0.1);
    padding-bottom: 120px;
}
.snow-monkey-form{
    max-width: 900px;
    margin: 0 auto;
}
.smf-form.smf-form--letter{
    margin-top: 100px;
}
/* 各フォームパーツのデザインをリセット */
.smf-button-control__control,
.smf-text-control__control,
.smf-textarea-control__control {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  font-size: 100% !important;
  font-family: inherit !important;
}

/* フォームボタンをデザイン */
.smf-button-control__control {
  background: var(--blue) !important;
  color: #fff !important;
  font-weight: bold;
  border: none !important;
  transition: background-color 0.3s ease !important;
  font-size: 20px !important;
  border-radius: 40px !important;
  padding: 25px 90px !important;
  line-height: 1.45em;
  letter-spacing: 0.1em;
}
.smf-action .smf-button-control__control:hover {
    box-shadow: 0 1px 2px rgba(0,0,0,0.3);
    transform: translate3d(0, 2px, 0);
    background: #191970 !important;
}

/* 「戻る」フォームボタンを個別にデザイン */
.smf-button-control__control[data-action="back"] {
  background: transparent !important;
  color: #333 !important;
  text-decoration: underline !important;
  
}

/* 「戻る」以外のフォームボタンのホバー＆フォーカス色 */
// .smf-button-control__control:not([data-action="back"]):hover,
// .smf-button-control__control:not([data-action="back"]):focus {
//   background: var(--dark-accent-color,#710c18) !important;
// }

.smf-item__label__text{
    color: var(--black);
    font-size: 16px;
}
.smf-item__controls{
    color: var(--gray);
    
}
.smf-form .smf-item .smf-text-control__control{
    background-color: var(--lightgray) !important;
    border: none !important;
    border-radius: 5px !important;
    // margin-right: 15px !important;
}
.smf-item__label__text::after{
    content: "必須";
    color: var(--white);
    background-color: var(--red);
    padding: 2px 13px;
    margin-left: 16px;
    font-size: 12px;
    line-height: 1.4em;
}
.optional .smf-item__label__text::after{
    content: none;
}
.smf-form .smf-item .smf-textarea-control__control{
    background-color: var(--lightgray) !important;
    border: none !important;
    border-radius: 5px ;
}
.p-contact-policy{
    overflow-y: scroll;
    height: 173px;
    margin-bottom: 21px;
}
.smf-form--letter .smf-item{
    padding: 1rem 15px 1rem 0 !important;
}
@media screen and (max-width: 576px){
    .smf-button-control__control {
        font-size: 18px !important;
        padding: 14px 90px !important;
      }
}